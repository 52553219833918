$text-color: #323130;

$color-primary-shade-30: #004a41;
$color-primary-shade-20: #006256;
$color-primary-shade-10: #0a766c;
$color-primary: #008374;
$color-primary-tint-10: #1e958a;
$color-primary-tint-20: #c9f2ed;
$color-primary-tint-30: #dff8f6;
$color-primary-tint-40: #f0fbfa;

$color-accent-shade-10: #a4262c;
$color-accent: #d13438;
$color-accent-tint-10: #c75033;

$color-black: #000000;
$color-gray-190: #201f1e;
$color-gray-160: #323130;
$color-gray-150: #3b3a39;
$color-gray-130: #605e5c;
$color-gray-120: #797775;
$color-gray-90: #a19f9d;
$color-gray-60: #c8c6c4;
$color-gray-50: #d2d0ce;
$color-gray-40: #e1dfdd;
$color-gray-30: #edebe9;
$color-gray-20: #f3f2f1;
$color-gray-10: #faf9f8;
$color-white: #ffffff;

$color-error-dark : #d83b01;
$color-error-light : #f55215;
$color-success-dark : #107c10;
$color-success-light : #3cb43c;

/* remove during production */
@import url('roboto-font.css');

*, ::after, ::before {
    box-sizing: border-box;
}

html {
    font-size: 15px;
    min-height: 100%;
    position: relative;
}

body {
    font-size: 1rem;
    color: $text-color;
    font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Open Sans", 
        "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", 
        "Droid Sans", "Helvetica Neue", sans-serif;
    margin: 0;
    margin-bottom: 60px;
    padding: 0;
    background-color: #f8fafa;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body.nav-open {
    overflow: hidden;
}

@media(max-width: 575px) {
    :not(input):not(textarea),
    :not(input):not(textarea)::after,
    :not(input):not(textarea)::before {
        -webkit-user-select: none;
        user-select: none;
    }
    body {
        background-color: #f0f2f2;
    }
}


.overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    background-color: rgba(0,0,0,0.25);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 950;
}
.overlay-enter {
    opacity: 0.1;
}
.overlay-enter.overlay-enter-active {
    opacity: 1;
    transition: all 0.1s ease-in-out;
}
.overlay-leave {
    opacity: 1;
}
.overlay-leave.overlay-leave-active {
    opacity: 0.1;
    transition: all 0.15s ease-in-out;
}
.modal-overlay,
.flyout-overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    background-color: rgba(0,0,0,0.25);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1150;
    overflow-x: hidden;
    overflow-y: auto;
}
.flyout-overlay {
    z-index: 1149;
}
.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
}
.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none;
    outline:  none;
    border: none;
    top: 25%;
    padding-bottom: 5rem;
}
.ReactModal__Content.modal-dialog {
    opacity: 0;
    transform: scale(0.5);
    transition: all 0.2s ease-in-out;
}
.ReactModal__Content--after-open.modal-dialog {
    opacity: 1;
    transform: scale(1);
}
.ReactModal__Content--before-close.modal-dialog {
    opacity: 0;
    transform: scale(0.8);
    transition: all 0.1s ease-in-out;
}
@media(min-width: 575px) {
    .modal-dialog {
        max-width: 500px;
        margin: auto;
        top: 30%;
        left: 0;
        right: 0;
    }
}

.modal-dialog.modal-valign-top {
    top: 8%;
}

.modal-content {
    background-color: #fff;
    overflow: auto;
    border-radius: 4px;
    width: 100%;
    box-shadow: 0 0 16px rgba(0,0,0,0.25);
    pointer-events: auto;
}

.modal-header {
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: bold;
    background-color: $color-gray-20;
}
.modal-close {
    float: right;
    background: transparent;
    border: none;
    color: #bbb;
    font-size: 0.5rem;
    padding-right: 0;
}
.modal-close:hover {
    color: #777;
    cursor: pointer;
}
.modal-body {
    padding: 1rem;
}
.modal-footer {
    padding: 1rem;
    display: table;
    width: 100%;
    text-align: right;
}
.modal-footer > :not(:last-child) {
    margin-right: 0.5rem;
}

.flyout {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
    pointer-events: none;
    outline:  none;
    border: none;
    float: right;
    top: 0;
    bottom: 0;
    height: 100%;
}
.ReactModal__Content.flyout {
    opacity: 0;
    right: -80px;
    transition: all 0.2s ease-in-out;
}
.ReactModal__Content--after-open.flyout {
    opacity: 1;
    right: 0;
}
.ReactModal__Content--before-close.flyout {
    opacity: 0;
    right: -40px;
    transition: all 0.1s ease-in-out;
}

.flyout-content {
    background-color: #fff;
    border-radius: 0;
    width: 100%;
    height: 100%;
    box-shadow: -4px 0 16px rgba(0,0,0,0.25);
    pointer-events: auto;

    overflow-y: hidden;
    flex-grow: 1;
    flex-direction: column;
    display: flex;
}

.flyout-header {
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: bold;
    background-color: $color-gray-20;
    flex-shrink: 0;
}
.flyout-close {
    float: right;
    background: transparent;
    border: none;
    color: #bbb;
    font-size: 0.5rem;
    padding-right: 0;
}
.flyout-close:hover {
    color: #777;
    cursor: pointer;
}
.flyout-body {
    padding: 1rem;
    overflow-y: auto;
    flex-grow: 1;
}
.flyout-footer {
    padding: 1rem;
    display: table;
    width: 100%;
    flex-shrink: 0;
    border-top: 1px solid $color-gray-40;
}
.flyout-content.has-scroll .flyout-footer {
    position: relative;
    border-top: $color-gray-20;
    box-shadow: 0 -2px 4px rgba(0,0,0,0.15);
}
.flyout-footer > :not(:last-child) {
    margin-right: 0.5rem;
}



a {
    color: $color-primary;
    text-decoration: none;
}
a:hover {
    color: $color-primary-shade-20;
    text-decoration: underline;
}
a > svg {
    vertical-align: middle;
    height: 1em;
    width: 1em;
}
.px-0 {
    padding-left: 0;
    padding-right: 0;
}
.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.mx-0 {
    padding-left: 0;
    padding-right: 0;
}
.mt-1 {
    margin-top: 1rem;
}
.mt-3 {
    margin-top: 3rem;
}
.mb-1 {
    margin-bottom: 1rem;
}
.mb-3 {
    margin-bottom: 3rem;
}
.text-right {
    text-align: right;
}
.text-center {
    text-align: center;
}
.text-error {
    color: $color-error-dark;
}
.text-success {
    color: $color-primary;
}
.text-muted {
    color: $color-gray-120;
}
.well {
    background-color: $color-gray-10;
    padding: 4rem;
    text-align: center;
    border-radius: 4px;
}
.well h1 {
    font-weight: 300;
    color: $color-gray-60;
}
.nlbr {
    white-space: pre-line;
}
hr {
    border: none;
    border-top: 1px solid $color-gray-30;
    margin-top: 1rem;
    margin-bottom: 2rem;
}
.container {
    width: 800px;
    display: table;
    margin: auto;
}
.nav ul {
    margin: 0;
    padding: 0;
}
.nav .nav-header {
    display: block;
    background-color: #fff;
    border-bottom: 1px solid #ededf6;
    padding: 1.4rem 1rem;
    text-decoration: none;
    font-weight: bold;
}
.nav .logo {
    display: inline-block;
    text-decoration: none;
    font-weight: bold;
    color: #009688;
    outline: none;
}
.nav .toggle-nav {
    float: right;
    text-decoration: none;
    position: relative;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    margin-top: 0.5rem;
    margin-right: 0.3rem;
    line-height: 1;
    border-radius: 3px;
    color: #009688;
    outline: none;
}

.nav .toggle-nav:hover {
    background-color: #eee;
}

.top-nav {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1100;
}

.top-nav .logo {
    text-decoration: none;
    font-weight: bold;
    text-decoration: none;
    font-weight: bold;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1.1rem;
    padding-bottom: 0.9rem;
}
.top-nav .logo img {
    height: 22px;
    width: auto;
}
@media (max-width: 575px) {
    .top-nav {
        box-shadow: 0 1px 1px rgba(0,0,0,0.0175);
        border-bottom: 1px solid $color-gray-40;
    }
    .top-nav .logo {
        padding-top: 0.6rem;
        padding-bottom: 0.4rem;
    }
    .top-nav .logo img {
        height: 26px;
        width: auto;
    }

    .nav .toggle-nav {
        margin-top: 0.15rem;
        margin-right: 0.3rem;
    }
}
.top-nav ul {
    float: right;
}
.top-nav li {
    list-style: none;
    display: inline-block;
}
.top-nav li > a, .top-nav li .btn {
    text-decoration: none;
    padding: 1.2rem 1rem;
    margin: 0;
    display: block;
    background-color: #fff;
    color: $color-primary;
    letter-spacing: 0;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid transparent;
    outline: none;
    transition: all 0.2s ease-in-out;
}
.top-nav li > a.active,
.top-nav li > a:focus,
.top-nav li > a:hover, 
.top-nav li .btn.active,
.top-nav li .btn:focus,
.top-nav li .btn:hover,
.top-nav li .dropdown-container.show > .btn {
    color: $color-primary-shade-10;
    border-bottom-color: $color-primary-shade-10;
    background-color: $color-primary-tint-40;
    box-shadow: none;
}
.top-nav .dropdown-container {
    display: inline-block;
}

.top-nav li a > svg, 
.top-nav li .btn > svg {
    margin-top: -3px;
}
.top-nav li a > svg.nav-user-icon, 
.top-nav li .btn > svg.nav-user-icon {
    background: $color-primary-tint-40;
    border-radius: 10rem;
    padding: 0.2rem;
    height: 1.8rem;
    width: 1.8rem;
    margin-top: -7px;
    margin-bottom: -7px;
}

.side-nav {
    width: 80%;
    background: $color-primary;
    position: fixed;
    min-height: 0;
    bottom: 0;
    top: 0;
    padding-top: 4.4rem;
    left: -101%;
    overflow-y: auto;
    z-index: 1000;
    transition: all 0.2s ease-in-out;
}
.side-nav.open {
    left: 0px;
    transition: all 0.2s ease-in-out;
}
@media (min-width: 768px) {
    .nav.side-nav {
        width: 16.666667%;
        left: 0px;
    }
}

.side-nav li {
    list-style: none;
}
.side-nav li .dropdown-menu {
    border-radius: 0;
    border-bottom: 1rem solid transparent;
}
.side-nav li a, .side-nav li .btn {
    text-decoration: none;
    display: block;
    padding: 1rem;
    color: #fff;
    transition: background-color ease-in-out 0.2s;
    font-weight: bold;
    outline: none;
    background-color: transparent;
    border: none;
    margin: 0;
    border-radius: 0;
    width: 100%;
    text-align: left;
}
.side-nav li a:hover, .side-nav li .btn:hover {
    color: #fff;
    background-color: $color-primary-shade-20;
}
.side-nav li a:focus, .side-nav li .btn:focus {
    background-color: $color-primary-tint-10;
}
.side-nav li a.active {
    background-color: $color-primary-shade-20;
}
.side-nav li a.active:hover {
    background-color: $color-primary-shade-20;
}
.side-nav li a.active:focus {
    box-shadow: 0 0 0 .2rem rgba(130, 168, 164, 0.5);
}
.side-nav li a svg {
    vertical-align: middle;
    margin-left: 4px;
    margin-right: 12px;
}

@media (max-width: 575px) {
    .side-nav {
        padding-top: 4rem;
    }
    .side-nav li a, .side-nav li .btn {
        padding: 0.85rem 1rem;
    }
}

.nav.sub-nav {
    padding: 0.5rem 0rem;
}
.sub-nav li {
    list-style: none;
    display: inline-block;
    margin-right: 0.4rem;
}
.sub-nav li:last-child {
    margin-right: 0;
}
.sub-nav li a,
.sub-nav li .btn {
    text-decoration: none;
    padding: 0.4rem 0.6rem;
    margin: 0;
    margin-bottom: 0.4rem;
    font-weight: 400;
    display: block;
    color: $text-color;
    outline: none;
    border-radius: 3px;
    border: 1px solid #fff;
    transition: all 0.1s ease-in-out;
    box-shadow: 0 0 4px rgba(0,0,0,0.1);
    background-color: #fff;
    letter-spacing: 0;
}
.sub-nav li a.active,
.sub-nav li .btn.active {
    background-color: $color-accent;
    border-color: $color-accent;
    color: #fff;
}
.sub-nav li a:hover,
.sub-nav li .btn:hover, 
.sub-nav li .btn.active:hover {
    background-color: $color-accent-shade-10;
    border-color: $color-accent-shade-10;
    color: #fff;
}
.sub-nav li a:focus,
.sub-nav li .btn:focus {
    box-shadow: 0 0 0 .2rem rgba(93, 137, 132, 0.5);
}

.sub-nav li a svg,
.sub-nav li .btn svg {
    height: 1.3em;
    width: 1.3em;
    vertical-align: middle;
    margin: 0;
}

.main {
    display: block;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 4.5rem;
}
.main > .header .sub-nav {
    float: right;
}
@media (max-width: 767px) {
    .main {
        padding-left: 0;
        padding-right: 0;
    }
    .main > .header .sub-nav {
        float: left;
    }
    .toolbar-buttons {
        text-align: left;
        padding-top: 1rem;
    }
}
.main > .header {
    padding: 1rem 1rem;
    padding-bottom: 0.5rem;
    top: 0;
    right: 0;
}
.main > .header h3 {
    margin: 0;
    padding: 0.6rem 0;
    font-weight: normal;
    font-size: 1.6rem;
}

@media (max-width: 575px) {
    .main {
        padding-top: 4rem;
    }

    .main > .header h3 {
        position: fixed;
        top: 4px;
        z-index: 1100;
        font-size: 1.2rem;
        left: 50px;
        right: 50px;
        text-align: center;
    }
}

.header.mobile {
    text-align: center;
    background-color: #fff;
    margin-top: 0rem;
    padding-top: 0.8rem;
    padding-bottom: 0.4rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1100;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
.header.mobile h3 {
    font-size: 1.2rem;
    padding: 0.3rem 0;
}
.header.mobile .row > [class^="col-"] {
    padding-left: 0.1rem !important;
    padding-right: 0.1rem;
}
.header.mobile a, .header.mobile .btn {
    background-color: transparent;
    border: none;
    border-radius: 10rem;
    padding: 0.15rem 0.15rem;
    margin: 0 0.2rem;
    display: inline-block;
    color: $color-primary;
    line-height: 1.6;
}
.header.mobile a svg, .header.mobile .btn svg{
    height: 1.6rem;
    width: 1.6rem;
}

.main > .content {
    padding: 1.5rem 1rem;
}

.homepage-body {
    background-image: url('/img/bottom-1.svg');
    background-position: center bottom;
    background-repeat: repeat-x;
    background-size: contain;
}

.homepage-heading {
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 3rem;
    font-size: 1.4rem;
}
.panel {
    background: #fff;
    box-shadow: 0 0px 20px rgba(0,0,0,0.08);
    border-radius: 10px;
    padding-left: 17px;
    padding-right: 17px;
    padding-bottom: 3px;
    margin-bottom: 2rem;
    display: table;
    width: 100%;
}
.panel-header {
    padding: 15px 0;
    display: table;
    width: 100%;
    position: relative;
}
.panel-header .btn {
    background: transparent;
    border: none;
    padding: 6px;
    border-radius: 10rem;
    margin-top: -5px;
    position: absolute;
    right: -8px;
}
.panel-header .btn:hover {
    background-color: $color-gray-30;
}
.panel-footer {
    border-top: 1px solid $color-gray-20;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 15px;
}
.panel .ph-line {
    height: 1rem;
    width: 100%;
    min-width: 1.75rem;
    margin: 1rem 0;
}
.panel-item {
    border-radius: 10px;
    color: $text-color;
}
a.panel-item:hover {
    background-color: $color-gray-20;
    cursor: pointer;
    text-decoration: none;
    color: $text-color;
}

@media(max-width: 575px) {
    .homepage-body {
        background-image: url('/img/bottom-2.svg');
    }
    .homepage-heading {
        margin-top: -1rem;
    }
}

.table-container {
    background-color: transparent;
    border-radius: 4px;
    box-shadow: 0 0 4px rgba(0,0,0,0.1);
}
.toolbar {
    padding: 1.5rem 0.8rem;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid $color-gray-30;
    background-color: #fff;
}
.toolbar-tools {
    padding-top: 0rem;
    margin-top: 1.5rem;
    border-top: 1px solid $color-gray-30;
}
.toolbar-tools-enter {
    opacity: 0.1;
    max-height: 0;
    margin-top: 0;
    overflow: hidden;
}
.toolbar-tools-enter.toolbar-tools-enter-active {
    opacity: 1;
    max-height: 200px;
    margin-top: 1.5rem;
    transition: all 0.1s ease-in-out;
}
.toolbar-tools-leave {
    opacity: 1;
    max-height: 200px;
    margin-top: 1.5rem;
    overflow: hidden;
}
.toolbar-tools-leave.toolbar-tools-leave-active {
    opacity: 0.1;
    max-height: 0;
    margin-top: 0;
    transition: all 0.1s ease-in-out;
}
.toolbar .input-group-text {
    background: transparent;
    border: none;
    position: absolute;
    z-index: 10;
    top: 0px;
    left: -5px;
    color: $color-gray-90;
}
.toolbar .input-group-text.focussed {
    color: $color-primary;
}

.toolbar-buttons > .form-group {
    margin: 0;
}

.toolbar-buttons > .form-group > label{
    display: none;
}
.filters-row > div {
    max-height: 400px;
    overflow-y: auto;
}

.filter-btns {
    margin-top: 1.5rem;
}
.table-outer {
    overflow-x: auto;
    background-color: #fff;
}
table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    min-width: 600px;
}
.modal-body table {
    min-width: unset;
}
th {
    font-weight: bold;
    padding: 0.6rem 0;
    border-bottom: 2px solid $color-gray-30;
}
th.thcol-100 {
    min-width: 100px;
}
th.thcol-150 {
    min-width: 150px;
}
th.thcol-175 {
    min-width: 175px;
}
th.thcol-200 {
    min-width: 200px;
}
th.thcol-250 {
    min-width: 250px;
}
.checkbox-col {
    width: 3rem;
}
.checkbox-col .custom-control {
    min-height: 1.5rem;
}
.empty-checkbox-col {
    width: 1rem;
}
th:first-child {
    padding-left: 0.5rem;
}
td {
    padding: 0.6rem 0;
    border-bottom: 1px solid $color-gray-30;
    font-weight: normal;
}
td:first-child {
    padding-left: 0.5rem;
}
tbody tr:hover {
    background-color: $color-gray-10;
}
.table-col-icon {
    width: 1.2rem;
    height: 1.2rem;
}

.table-item-card {
    border: 1px solid $color-gray-30;
    display: block;
    color: $color-gray-130;
    padding: 1rem;
    position: relative;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(0,0,0,0.045);
    background-color: #fff;
    margin-bottom: 0.6rem !important;
    margin-top: 2px;
    word-break: break-all;
}
.table-item-card:hover {
    text-decoration: none;
    background-color: $color-gray-10;
}
.table-item-card .caret {
    width: 20px;
    position: absolute;
    right: 12px;
    top: 28px;
    color: $color-gray-60;
}
.table-item-card .caret > svg {
    height: 1.8rem;
    width: 1.8rem;
}
.table-item-card .col-label {
    color: $color-gray-90;
    font-size: 0.8rem;
    display: block;
    margin-bottom: 0.25rem;
}
.table-item-card .item-title {
    font-size: 1.1rem;
    color: $color-gray-190;
    padding-bottom: 0.5rem;
    display: block;
    font-weight: bold;
}
/*
a.table-item-card .item-title {
    color: $color-primary;
}
*/
.table-item-card > .row > div[class^="col-"] {
    padding-bottom: 0.8rem;
}
.table-item-card .actions-col > .btn.link,
.table-item-card .actions-col > .btn.link2 {
    background-color: $color-primary-tint-40;
    text-decoration: none;
    padding: 0.4rem 0.6rem;
}

.hsn-table td {
    cursor: pointer;
}
.hsn-toolbar {
    padding-top: 0;
    border-radius: 0 !important;
}


.flash {
    -webkit-animation: animateBgColor 1.5s 1 linear;
    animation: animateBgColor 1.5s 1 linear;
}

@-webkit-keyframes animateBgColor {
  from {
    background-color: #ffba53;
  }
  to {
    background-color: #fff;
  }
}
@keyframes animateBgColor {
  from {
    background-color: #ffba53;
  }
  to {
    background-color: #fff;
  }
}


.form-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}
.form-row.bordered {
    border-bottom: 1px solid $color-gray-30;
    margin-bottom: 1.5rem;
    padding-bottom: 0.5rem;
}
.form-row.dismissable {
    position: relative;
}
.form-row.dismissable .btn-close {
        position: absolute;
        top: -0.8rem;
        right: 0;
}
.form-row > .col, .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
}
.form-group-row {
    padding-left: 15px;
    padding-right: 15px;
}
.form-group-row > .col, .form-group-row > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}
.form-group-row > .col:not(:first-child), .form-group-row > [class*="col-"]:not(:first-child) {
    margin-left: -1px;
}
.form-group-row label {
    min-height: 1.2rem;
}
.form-group-row > .col input, 
.form-group-row > [class*="col-"] input {
    position: relative;
}
.form-group-row > .col input:focus, 
.form-group-row > [class*="col-"] input:focus {
    z-index: 1
}
.form-group-row > .col:first-child input, 
.form-group-row > [class*="col-"]:first-child input,
.form-group-row > .col:first-child .react-select__control, 
.form-group-row > [class*="col-"]:first-child .react-select__control {
    border-radius: 3px 0 0 3px !important;
}
.form-group-row > .col:last-child input, 
.form-group-row > [class*="col-"]:last-child input, 
.form-group-row > .col:last-child .react-select__control, 
.form-group-row > [class*="col-"]:last-child .react-select__control {
    border-radius: 0 3px 3px 0 !important;
}
.form-group-row .react-select__control,
.form-group-row input.form-control {
    height: 35px !important;
}
.form-container {
    padding: 1rem;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 4px rgba(0,0,0,0.1);
}

@media(max-width: 575px) {
    .form-container {
        margin-left: -8px;
        margin-right: -8px;
        border: 1px solid $color-gray-30;
        box-shadow: 0 0px 2px rgba(0,0,0,0.05);
    }
    .table-container {
        margin-left: -8px;
        margin-right: -8px;
        box-shadow: none;
        border-top: 1px solid $color-gray-30;
    }

    .table-container > .toolbar > .row {
        margin-right: -12px;
    }

    .table-outer {
        background-color: transparent;
    }

    .table-container-header {
        border: 1px solid $color-gray-30;
    }

    .toolbar {
        border: 1px solid $color-gray-30;
        border-top: none;
        margin-bottom: 2rem;
        border-radius: 4px;
    }
}

.form-section-header {
    background: $color-gray-10;
    margin-left: -1rem;
    margin-right: -1rem;
    padding: 1rem;
    border-bottom: 1px solid $color-gray-30;
    border-top: 1px solid $color-gray-30;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
}
.form-service-header {
    margin-top: 0;
    font-weight: normal;
    color: $color-gray-90;
}
.table-container-header {
    background: $color-gray-10;
    padding: 1rem;
    border-bottom: 1px solid $color-gray-30;
    margin-top: 1.5rem;
    margin-bottom: 0;
    border-radius: 4px 4px 0 0;
}
.form-footer {
    background-color: $color-gray-10;
    padding: 0 1rem;
    margin-bottom: -2.5rem;
    margin-top: 1.5rem;
    font-size: 0.9rem;
    border-top: 1px solid $color-gray-30;
}
.form-group {
    width: 100%;
    margin-bottom: 1.5rem;
}
.form-btns {
    margin-top: 2.5rem;
    padding-top: 2rem;
    border-top: 1px solid $color-gray-30;
}
.form-btns .btn.danger {
    float: right;
}
label {
    color: $color-gray-130;
    margin-bottom: 0.5rem;
    display: block;
    font-weight: normal;
} 
.required-label {
    color: $color-error-dark;
}
.form-group.error label {
    color: $color-error-dark;
}

.form-control {
    width: 100%;
    max-width: 100%;
    display: block;
    font-size: 1rem;
    padding: 0.5rem;
    border: 1px solid $color-gray-50;
    border-radius: 3px;
    background-color: #fff;
    font-family: inherit;
}
.form-control:focus {
    border-color: $color-primary;
    box-shadow: 0 0 0 1px $color-primary;
    outline: none;
}
.form-control:disabled, .form-control[readonly] {
    background-color: $color-gray-20;
    color: $text-color;
    opacity: 1;
    cursor: default;
}
.form-control.icon-prepend {
    padding-left: 35px;
}
.react-select__control--is-disabled {
    background-color: $color-gray-20 !important;
}
.react-select__single-value--is-disabled {
    color: $text-color !important;
}
.form-group.error .form-control {
    border-color: $color-error-dark;
}
.form-group.error .form-control:focus {
    box-shadow: 0 0 0 1px $color-error-dark;
}

.react-select__control {
    min-height: 35px !important;
    border-color: $color-gray-60 !important;
    border-radius: 3px !important;
}
.react-select__indicator {
    padding: 6px 8px !important;
}
.react-select__control:hover {
    border-color: $color-gray-60 !important;
}
.react-select__control--is-focused,
.react-select__control--is-focused:hover {
    border-color: $color-primary !important;
    box-shadow: 0 0 0 1px $color-primary !important;
}
.form-group.error .react-select__control {
    border-color: $color-error-dark !important;
}
.form-group.error .react-select__control--is-focused {
    box-shadow: 0 0 0 1px $color-error-dark !important;
}
.react-select__option--is-focused {
    background-color: $color-gray-20 !important;
    color: $text-color !important;
}
.react-select__option--is-selected {
    background-color: $color-primary !important;
    color: #fff !important;
}

.react-select-loader {
    width: 1rem;
    height: 1rem;
    border-width: 3px !important;
    float: left;
    margin: 0;
        margin-top: 0px;
        margin-right: 0px;
    margin-top: 0.1rem;
    margin-right: 0.6rem;
}

.typeahead {
    position: relative;
}

.typeahead__options {
    display: none;
    background: #fff;
    padding: 0.25rem 0;
    border: 1px solid #e3e1e0;
    border-radius: 3px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.175);
    position: absolute;
    width: 100%;
    z-index: 10;
    margin-top: -1.25rem;
    max-height: 250px;
    overflow-y: auto;
}

.typeahead__options--shown {
    display:  block;
}

.typeahead__option {
    display: block;
    padding: 0.6rem;
    cursor: pointer;
    line-height: 1.4;
    border-bottom: 1px solid $color-gray-20;
}
.typeahead__option:last-child {
    border-bottom: none;
}
.typeahead__option:hover,
.typeahead__option--is-focused {
    background-color: $color-gray-20;
}
.typeahead__option--is-selected {
    background-color: $color-primary;
    color: #fff;
}
.typeahead__option__title {
    display: block;
    font-weight: bold;
}
.typeahead__option__extra-data {
    display: block;
    color: $color-gray-120;
    font-size: 0.9rem;
    white-space: pre-line;
}

form .help-text {
    display: block;
    font-size: 0.85rem;
    color: $color-gray-120;
    margin-top: 0.3rem;
    padding-left: 0.3rem;
}
.form-group.error .help-text {
    color: $color-error-dark;
}
.form-group .btn {
    display: inline-block;
    margin-right: 0.5rem;
}
.custom-control {
    position: relative;
    display: block;
    min-height: 2.2rem;
    padding-left: 0;
}
.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    left: 0;
}
.custom-control-label {
    position: relative;
    padding-left: 2.2rem;
    padding-top: 2px;
    margin-bottom: 0;
    cursor: pointer;
}
.custom-checkbox .custom-control-indicator::before {
    border-radius: 2px;
    cursor: pointer;
}
.custom-radio .custom-control-indicator::before {
    border-radius: 50%;
}
.custom-control-indicator::before, .custom-file-label, .custom-select {
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.custom-checkbox .custom-control-indicator::before {
    position: absolute;
    top: 0;
    left: 0.2rem;
    display: block;
    width: 1.4rem;
    height: 1.4rem;
    pointer-events: none;
    content: "";
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: $color-white;
    border: 1px solid $color-gray-90;
}

.custom-checkbox .custom-control-label:hover .custom-control-input:not(:disabled):not(:checked) ~ .custom-control-indicator::before,
.custom-checkbox .custom-control-input:focus:not(:disabled):not(:checked) ~ .custom-control-indicator::before {
    background-color: $color-gray-20;
    border-color: $color-gray-150;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator::before {
    background-color: $color-primary;
    border-color: $color-primary;
}
.custom-checkbox .custom-control-indicator::after {
    position: absolute;
    top: -0.2rem;
    left: 0rem;
    display: block;
    width: 1.8rem;
    height: 1.8rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-indicator::before {
    content: "";
    display: inline-block;
    background-color: $color-white;
    border-width: 1px;
    border-style: solid;
    border-color: $color-gray-90;
    width: 1.45rem;
    height: 1.45rem;
    font-weight: normal;
    position: absolute;
    top: 0px;
    left: 0px;
    box-sizing: border-box;
    transition-property: border-color;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.23, 1);
    border-radius: 50%;
}
.custom-radio .custom-control-label:hover .custom-control-input:not(:disabled) ~ .custom-control-indicator::before,
.custom-radio .custom-control-input:focus:not(:disabled) ~ .custom-control-indicator::before {
    background-color: $color-gray-20;
    border-color: $color-gray-150;
}
.custom-radio .custom-control-input:checked ~ .custom-control-indicator::before {
    background-color: $color-white;
    border-color: $color-primary;
}
.custom-radio .custom-control-input:checked:focus ~ .custom-control-indicator::before,
.custom-radio .custom-control-input:checked:hover ~ .custom-control-indicator::before,
.custom-radio .custom-control-label:hover .custom-control-input:checked ~ .custom-control-indicator::before {
    background-color: $color-gray-20;
    border-color: $color-primary;
}
.custom-radio .custom-control-indicator::after {
    content: "";
    width: 0px;
    height: 0px;
    border-radius: 50%;
    position: absolute;
    left: 0.7rem;
    right: 0px;
    transition-property: border-width;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.23, 1);
    box-sizing: border-box;
}
.custom-radio .custom-control-input:checked ~ .custom-control-indicator::after {
    width: 0.7rem;
    height: 0.7rem;
    left: 0.37rem;
    right: 0px;
    border-width: 0.37rem;
    border-style: solid;
    border-color: $color-primary;
    top: 0.37rem;
}

.custom-control-input:disabled ~ .custom-control-indicator {
    color: $color-gray-90;
    cursor: default;
}
.custom-control-input:disabled ~ .custom-control-indicator::before {
    background-color: $color-gray-40;
    border-color: $color-gray-40;
}
.custom-control-input:checked:disabled ~ .custom-control-indicator::before {
    background-color: $color-gray-40;
    border-color: $color-gray-40;
}
.custom-radio .custom-control-input:checked:disabled ~ .custom-control-indicator::before {
    background-color: $color-white;
    border-color: $color-gray-40;
}
.custom-radio .custom-control-input:checked:disabled ~ .custom-control-indicator::after {
    border-color: $color-gray-40;
}
.form-check {
    position: relative;
    display: block;
}
.form-check-inline {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0;
    margin-right: .75rem;
    margin-top: 0.25rem;
}
label.form-check-label {
    display: inline-block;
    font-weight: normal;
}
label > input[type="checkbox"],
label > input[type="radio"] {
    vertical-align: top;
    float: left;
    margin-top: 1px;
    margin-right: 0.5rem;
}
.form-check-inline > .custom-control {
    min-height: 1.5rem;
}
.form-check-input {
    position: absolute;
    margin-left: -1.5rem;
    margin-top: 1px;
}
.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: .3125rem;
    margin-left: 0;
    vertical-align: middle;
}
button {
    font-family: inherit;
}
.btn {
    font-size: 1rem;
    padding: 0.4rem 0.8rem;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
}
.btn-sm {
    padding: 0.2rem 0.4rem;
    font-size: 0.9rem;
}
.btn:focus {
    box-shadow: 0 0 0 .2rem rgba(93, 137, 132, 0.5);
    outline: none;
}
.btn::-moz-focus-inner {
  border: 0;
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn {
    font-size: 1rem;
    font-weight: 500;
    padding: 0.5rem 1.2rem;
    margin: 2px 2px;
    cursor: pointer !important;
    transition: all 0.15s ease-in-out;
    display: inline-block;
    letter-spacing: 0px;
}
.btn-sm {
    padding: 0.35rem 0.7rem;
    font-size: 0.9rem;
    letter-spacing: 0;
}
.btn:focus {
    outline: none;
}
.btn::-moz-focus-inner {
  border: 0;
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn.primary{
    background-color: $color-primary;
    border: 1px solid $color-primary;
    color: #fff;
}
.btn.primary:hover {
    background-color: $color-primary-shade-10;
    border-color: $color-primary-shade-10;
    color: #fff;
}
.btn.primary:focus {
    background-color: $color-primary-shade-20;
    border-color: $color-primary-shade-30;
}
.btn.primary:disabled {
    background-color: $color-primary-tint-30;
    border: 1px solid $color-primary-tint-30;
    color: $color-primary-tint-10;
}
.btn.outline-primary {
    background-color: #fff;
    border: 1px solid $color-primary;
    color: $color-primary;
}
.btn.outline-primary:hover {
    background-color: $color-primary-shade-10;
    border-color: $color-primary-shade-10;
    color: #fff;
}
.btn.secondary {
    background-color: $color-accent;
    border: 1px solid $color-accent;
    color: #fff;
}
.btn.secondary:hover {
    background-color: $color-accent-shade-10;
    border-color: $color-accent-shade-10;
}
.btn.danger{
    background-color: transparent;
    border: 1px solid $color-error-dark;
    color: $color-error-dark;
}
.btn.danger:hover {
    background-color: #f02929;
    color: #fff;
}

.btn.link {
    background: transparent;
    border: none;
    text-decoration: underline;
    font-weight: bold;
    color: $color-primary;
    text-transform: uppercase;
}
.btn.link:hover {
    background-color: $color-primary-tint-40;
}
.btn.link2 {
    background: transparent;
    border: none;
    text-decoration: underline;
    font-weight: bold;
    color: $color-primary;
    text-transform: uppercase;
    padding: 0.2rem 0.2rem;
}
.btn.link2:hover {
    background-color: $color-primary-tint-40;
}
.btn.default {
    background-color: $color-gray-20;
    color: $color-gray-130;
    border: 1px solid $color-gray-20;
}
.btn.default:hover {
    background-color: $color-gray-30;
    color: $color-black;
    border-color: $color-gray-30;
}
.btn.default:focus {
    background-color: $color-gray-30;
    border-color: $color-primary;
}
.btn svg {
    vertical-align: middle;
    height: 1.3em;
    width: 1.3em;
    margin-top: -3px;
}
.btn-close {
    background: transparent;
    border: none;
    color: #e1bbbb;
    font-size: 0.5rem;
    border-radius: 10rem;
    padding: 5px;
}
.btn-close:hover,
.btn-close:focus {
    color: #f02929;
    cursor: pointer;
    background-color: #fff1f1;
}
.btn-close > svg {
    height: 24px;
    width: 24px;
    vertical-align: middle;
}
.dropdown-container, .dropleft-container, .dropright-container, .dropup-container {
    position: relative;
}
.dropdown::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}
.dropup::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent;
}
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    width: 100%;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: .125rem;
    transform-origin: top;
}
.dropdown-menu.right {
    left: unset;
    right: 0;
    transform-origin: top;
}
.dropdown-menu.show {
    display: table;
    box-shadow: 0 3px 4px rgba(0,0,0,0.15);
}
.dropdown-menu[x-placement^="bottom"], 
.dropdown-menu[x-placement^="left"], 
.dropdown-menu[x-placement^="right"], 
.dropdown-menu[x-placement^="top"] {
    right: auto;
    bottom: auto;
}
.dropdown-menu-enter {
    opacity: 0.1;
    transform: scale(0.95);
    margin-top: -0.5rem;
}
.dropdown-menu-enter.dropdown-menu-enter-active {
    opacity: 1;
    transform: scale(1);
    margin-top: .125rem;
    transition: all 0.1s ease-in-out;
}
.dropdown-menu-leave {
    opacity: 1;
    transform: scale(1);
    margin-top: .125rem;
}
.dropdown-menu-leave.dropdown-menu-leave-active {
    opacity: 0.1;
    transform: scale(0.95);
    margin-top: -0.5rem;
    transition: all 0.1s ease-in-out;
}
.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.6rem 0.8rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}
button.dropdown-item {
    font-size: 1rem;
    cursor: pointer;
}
.dropdown-item:focus, .dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f0f9f7;
}
.dropdown-item.active {
    color: #fff;
    text-decoration: none;
    background-color: #009688;
}
.input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
}
.input-group > .custom-select:not(:last-child), .input-group > .form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group > .custom-file, .input-group > .custom-select, .input-group > .form-control {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;

}
.input-group-prepend {
    margin-right: -1px;
}
.input-group-append, .input-group-prepend {
    display: -ms-flexbox;
    display: flex;
}
.input-group-append {
    margin-left: -1px;
}
.input-group-append, .input-group-prepend {
    display: -ms-flexbox;
    display: flex;
}

.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), 
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child), 
.input-group > .input-group-append:not(:last-child) > .btn, 
.input-group > .input-group-append:not(:last-child) > .input-group-text, 
.input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn, 
.input-group > .input-group-append > .input-group-text, 
.input-group > .input-group-prepend:first-child > .btn:not(:first-child), 
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child), 
.input-group > .input-group-prepend:not(:first-child) > .btn, 
.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-append .btn, .input-group-prepend .btn {
    position: relative;
    z-index: 2;
    margin: 0;
}
.input-group-append .btn.default,
.input-group-append .btn.link,
.input-group-prepend .btn.default,
.input-group-prepend .btn.link {
    border: 1px solid $color-gray-50;
}
.input-group-append .btn.primary,
.input-group-prepend .btn.primary {
    border: 1px solid $color-primary-shade-20;
}

.input-group-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}


.signature-thumbnail {
    height: 43px;
    max-width: 100%;
    border: 1px solid $color-gray-50;
    border-radius: 3px;
    padding: 3px;
}


.alert {
    padding: 1rem;
    border-radius: 3px;
}
.alert.error {
    background-color: $color-error-dark;
    color: #fff;
}

.form-btns .alert,
.flyout-footer .alert {
    margin-left: -15px;
    margin-right: -15px;
    border-radius: 0;
    margin-top: -2rem;
    margin-bottom: 1.5rem;
}
.flyout-footer .alert {
    margin-top: -0.9rem;
}

/* loader */

.loader {
  width: 32px;
  height: 32px;
  clear: both;
  margin: 20px auto;
}

.loader.circle {
  border: 4px #B2DFDB solid;
  border-top: 4px $color-primary solid;
  border-radius: 50%;
  -webkit-animation: spCircRot .4s infinite linear;
  animation: spCircRot .4s infinite linear;
}

@-webkit-keyframes spCircRot {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@keyframes spCircRot {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.btn .loader.circle {
    width: 1rem;
    height: 1rem;
    border-width: 2px;
    display: inline-block;
    vertical-align: middle;
    margin: auto;
    margin-left: 0.5rem;
}
.btn.primary .loader.circle {
    border-color: #D4F0ED;
    border-top-color: #00796B;
}
.btn.danger .loader.circle {
    border-color: #f02929;
    border-top-color: #fff;
}


.toast-container {
    position: fixed;
    top: 0;
    z-index: 1200;
    height: 0;
    left: 0;
    right: 0;
    padding-left: 15px;
    padding-right: 15px
}
.toast {
    display: table;
    background: #ffe087;
    margin: auto;
    margin-top: 0.7rem;
    padding: 0.6rem 1rem;
    color: #594818;
    border-radius: 3px;
    box-shadow: 0px 3px 8px rgba(0,0,0,0.175);
    border: 1px solid #dabe71;
    font-weight: bold;
    text-shadow: 1px 1px #fff5d7;
    font-size: 0.9rem;
}
.toast:hover {
    box-shadow: 0px 3px 12px rgba(0,0,0,0.2);
}
.toast > div {
    display: table-cell;
    vertical-align: middle;
}
.toast > .icon {
    line-height: 0.9;
}
.toast > .icon.with-btn {
    padding-top: 2px;
}
.toast > .icon svg {
    height: 1.3rem;
    width: 1.3rem;
}
.toast > .message {
    max-width: 25rem;
    min-width: 10rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.toast > .message .btn {
    padding: 4px 2px;
}
.toast > .close > button {
    font-size: 1.6rem;
    line-height: 0.9;
    border-radius: 10rem;
    text-align: center;
    font-weight: normal;
    width: 2rem;
    height: 2rem;
    background: $color-gray-10;
    border: none;
    color: inherit;
    cursor: pointer;
}

.toast.success {
    background-color: #fff;
    color: #26aa51;
    text-shadow: none;
    border-color: #26aa51;

}

.toast.error {
    background-color: #fff;
    color: #f02929;
    border-color: #f02929;
    text-shadow: none;
}

.toast.loader-toast {
    color: #009688;
    border-color: #009688;
    text-shadow: none;
    background-color: #fff;
    cursor: default;
}
.toast.loader-toast .message {
    min-width: 0;
    padding-top: 0;
}
.toast .loader {
    width: 1rem;
    height: 1rem;
    border-width: 3px !important;
    float: left;
    margin: 0;
    margin-top: 0.1rem;
    margin-right: 0.6rem;
}
.react-select-loader {
    width: 1.2rem;
    height: 1.2rem;
    border-width: 3px !important;
    float: left;
    margin: 0;
    margin-top: 0;
    margin-right: 0.6rem;
}
.toast-animate-enter {
    opacity: 0.1;
    margin-top: -1rem;
}
.toast-animate-enter.toast-animate-enter-active {
    opacity: 1;
    margin-top: .7rem;
    transition: all 0.1s ease-in-out;
}
.toast-animate-leave {
    opacity: 1;
    margin-top: .7rem;
}
.toast-animate-leave.toast-animate-leave-active {
    opacity: 0.1;
    margin-top: -1rem;
    transition: all 0.1s ease-in-out;
}

/* login form */
html.full-height {
    min-height: 100%;
}
html.full-height > body {
    min-height: 100%;
}
html.full-height #root {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
}
.form-signin {
    width: 100%;
    max-width: 330px;
    padding: 1rem;
    margin: 0 auto;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 4px;
    box-shadow: 0 0 4px rgba(0,0,0,0.15);
}
.form-signin .form-control {
    padding: 0.9rem;
}
.form-signin .btn {
    padding: 0.8rem;
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}
.form-signin > h2 {
    border-bottom: 1px solid #ededed;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: -1rem;
    margin-left: -1rem;
    margin-right: -1rem;
    margin-bottom: 2.5rem;
}
.form-signin-logo {
    width: 115px;
    display: table;
    margin: auto;
    margin-bottom: 2.8rem;
}
.form-signin-bottom {
    font-size: 0.9rem;
    text-align: center;
    margin-top: 3rem;
    color: #888;
}
.form-signin .alert {
    margin-bottom: 2rem;
}
.splash {
    position: absolute; 
    display: table; 
    left: 0; 
    right: 0; 
    margin: auto; 
    top: 30%;
    width: 100%;
    text-align: center;
}
.splash img {
    height: 124px;
    width: auto;
}

.main > .splash {
    top: 110%;
}

.pagination {
    display: table;
    background: #fff;
    margin-top: 1.5rem;
    padding-top: 0.5rem;
    padding-left: 1rem;
    padding-right: 1.2rem;
    box-shadow: 0 0 4px rgba(0,0,0,0.1);
    border-radius: 4px;
}
@media(max-width: 575px) {
    .pagination {
        box-shadow: none;
    }
}
.pagination a, 
.pagination > span {
    padding: 0.4rem 0.2rem;
    margin: 0.1rem;
    background-color: #fff;
    display: block;
    float: left;
}
.pagination > span {
    color: $color-gray-120;
}
.pagination a:first-child,
.pagination span:first-child {
    border-radius: 3px 0 0 3px;
}
.pagination a:last-child,
.pagination span:last-child {
    border-radius: 0 3px 3px 0;
}
.pagination .active {
    font-weight: bold;
    text-decoration: underline;
    color: $color-gray-190;
}
.pagination a:hover {
    padding-top: 0.3rem;
    padding-bottom: 0.5rem;
}
.pagination a > svg, 
.pagination span > svg {
    height: unset;
    width: unset;
    margin-top: -0.15rem;
}

/* skeletons */
@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }
  
  100% {
    background-position: 468px 0; 
  }
}

.placeholder {
    background: #f6f7f8;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px; 
    display: inline-block;
    position: relative; 
    animation-duration: 1s;
    animation-fill-mode: forwards; 
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
}

.ph-input {
    height: 2.5rem;
    width: 100%;
    margin-bottom: 1rem;
    display: block;
    border-radius: 3px;
}
.ph-button {
    height: 2.5rem;
    width: 7rem;
    border-radius: 3px;
    margin-right: 1rem;
}
.ph-line {
    height: 0.8rem;
    width: 45%;
    min-width: 1.75rem;
}
@media(max-width: 575px) {
    .ph-line {
        width: 100%;
        margin-bottom: 0.8rem;
    }
}

.ph-checkbox {
    height: 1.2rem;
    width: 1.4rem;
}

.skeleton-table {
    padding: 0 1rem;
    margin-top: 0rem;
    background-color: #fff;
    box-shadow: 0 0 4px rgba(0,0,0,0.1);
}
.skeleton-table > .header {
    padding-top: 1.5rem;
}
.skeleton-table > .row {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #eee;
}

.skeleton-form .placeholder-field .placeholder-label {
    height: 0.8rem;
    width: 8rem;
    margin-bottom: 0.8rem;
    display: block;
}
.skeleton-form .placeholder-buttons {
    border-top: 1px solid #eee;
    margin-top: 2.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: table;
    width: 100%
}
.skeleton-form .ph-button {
    float: left;
    margin-top: 1rem;
}

.ph-right {
    float: right !important;
}

.add-to-home {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: auto;
    background: #fff;
    text-align: center;
    box-shadow: 0 -1px 3px rgba(0,0,0,0.15);
    padding: 0.5rem 0.1rem;
    z-index: 900;
}

.add-to-home .modal-close {
    margin-right: 7px;
    margin-top: 4px;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: 0.8rem;
    color: #93a8a8;
    padding: 0 3rem 0.7rem 0;
    text-align: right;
}
@media (max-width: 767px) {
    .footer {
        padding-left: 15px;
        padding-right: 15px;
    }
}
@media (max-width: 575px) {
    .footer {
        opacity: 0.4;
    }
    .homepage-body .footer {
        color: #fff;
    }
}